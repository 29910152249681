export default function (){
    return [
        { 
            'id' : 1 ,
            'imgUrl' : 'http://localhost/casaRhin/imagesBack/moscatelo.jpg',
            'name' : 'moscatelo',
            'description':  'En la licuadora mezcle el jugo con el azúcar, hielo y luego adicione el Vodka Katiuskaya. Sirva en un vaso de whisky',
            'active' : true,
            'pictures' : [
                {
                    'name' : 'no name',
                    'urlImage' : 'http://localhost/casaRhin/imagesBack/moscatelo.jpg'
                },
                {
                    'name' : 'no name',
                    'urlImage' : 'http://localhost/casaRhin/imagesBack/hot_yellow.jpg'
                },
                {
                    'name' : 'no name',
                    'urlImage' : 'http://localhost/casaRhin/imagesBack/atardecer.jpg'
                },                
            ]
        },
        { 
            'id' : 2 ,
            'imgUrl' : 'http://localhost/casaRhin/imagesBack/hot_yellow.jpg',
            'name' : 'hot yellow',
            'description' : 'En la licuadora mezcle el jugo con el azúcar, hielo y luego adicione el Vodka Katiuskaya. Sirva en un vaso de whisky',
            'active' : true,
            'pictures' : [
                {
                    'name' : 'no name',
                    'urlImage' : 'http://localhost/casaRhin/imagesBack/moscatelo.jpg'
                },
                {
                    'name' : 'no name',
                    'urlImage' : 'http://localhost/casaRhin/imagesBack/hot_yellow.jpg'
                },
                {
                    'name' : 'no name',
                    'urlImage' : 'http://localhost/casaRhin/imagesBack/atardecer.jpg'
                },                
            ]
        },
        { 
            'id' : 3 ,
            'imgUrl' : 'http://localhost/casaRhin/imagesBack/atardecer.jpg',
            'name' : 'atardecer',
            'description' : 'En la licuadora mezcle el jugo con el azúcar, hielo y luego adicione el Vodka Katiuskaya. Sirva en un vaso de whisky',
            'active' : true,
            'pictures' : [
                {
                    'name' : 'no name',
                    'urlImage' : 'http://localhost/casaRhin/imagesBack/moscatelo.jpg'
                },
                {
                    'name' : 'no name',
                    'urlImage' : 'http://localhost/casaRhin/imagesBack/hot_yellow.jpg'
                },
                {
                    'name' : 'no name',
                    'urlImage' : 'http://localhost/casaRhin/imagesBack/atardecer.jpg'
                },                
            ]
        },  
        { 
            'id' : 4,
            'imgUrl' : 'http://localhost/casaRhin/imagesBack/atardecer.jpg',
            'name' : 'atardecer',
            'description' : 'En la licuadora mezcle el jugo con el azúcar, hielo y luego adicione el Vodka Katiuskaya. Sirva en un vaso de whisky',
            'active' : true,
            'pictures' : [
                {
                    'name' : 'no name',
                    'urlImage' : 'http://localhost/casaRhin/imagesBack/moscatelo.jpg'
                },
                {
                    'name' : 'no name',
                    'urlImage' : 'http://localhost/casaRhin/imagesBack/hot_yellow.jpg'
                },
                {
                    'name' : 'no name',
                    'urlImage' : 'http://localhost/casaRhin/imagesBack/atardecer.jpg'
                },                
            ]
        },       
    ]

}
